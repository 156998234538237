import mutationTypes from './mutationTypes';

export default {
  [mutationTypes.SET_TRANSLATIONS](state, translations) {
    state.translations = translations;
  },
  [mutationTypes.SET_LANGUAGES](state, languages) {
    state.languages = languages;
  },
  [mutationTypes.SET_FILTER_CONFIG](state, moduleName) {
    state.filterConfig = state[moduleName].filterConfig;
  },
  [mutationTypes.SET_FILTER_PARAMS](state, filterParams) {
    state.filterParams = filterParams;
  },
  [mutationTypes.CLEAR_STATUS_MESSAGE](state) {
    state.statusMessage = '';
  },
  [mutationTypes.SET_STATUS_MESSAGE](state, options) {
    state.statusMessage = options;
  },
  [mutationTypes.TOGGLE_LOADER](state, value) {
    state.isLoaderVisible = value;
  },
  [mutationTypes.SET_USER](state, data) {
    state.user = data;
  },
  [mutationTypes.SET_USER_MODULES](state, data) {
    state.userModules = data;
  },
};
