import { handleError } from '../actions';
import mutationTypes from '../mutationTypes';
import api from '../../api';

export default {
  state: {
    companies: [],
    categories: [],
    types: [],
    aggregators: [],
    providers: [],
    activities: [
      {
        title: 'Active',
        value: true,
      },
      {
        title: 'Inactive',
        value: false,
      },
    ],
    statuses: [
      {
        title: 'Enabled',
        value: true,
      },
      {
        title: 'Disabled',
        value: false,
      },
    ],
  },

  mutations: {
    [mutationTypes.SET_COMPANIES](state, companies) {
      state.companies = companies;
    },
    [mutationTypes.SET_CATEGORIES](state, categories) {
      state.categories = categories;
    },
    [mutationTypes.SET_TYPES](state, types) {
      state.types = types;
    },
    [mutationTypes.SET_AGGREGATORS](state, aggregators) {
      state.aggregators = aggregators;
    },
    [mutationTypes.SET_PROVIDERS](state, providers) {
      state.providers = providers;
    },
  },

  actions: {
    getCompanies: ({ commit }) => api.getCompanies()
      .then((companies) => {
        commit(mutationTypes.SET_COMPANIES, companies);
      })
      .catch((err) => {
        handleError({ commit }, err);
      }),
    getCategories: ({ commit }) => api.getCategories()
      .then((categories) => commit(mutationTypes.SET_CATEGORIES, categories))
      .catch((err) => handleError({ commit }, err)),
    getTypes: ({ commit }) => api.getTypes()
      .then((types) => commit(mutationTypes.SET_TYPES, types))
      .catch((err) => handleError({ commit }, err)),
    getAggregators: ({ commit, getters }) => api.getAggregators(getters.getFilterParams.companyId)
      .then((aggregators) => commit(mutationTypes.SET_AGGREGATORS, aggregators))
      .catch((err) => handleError({ commit }, err)),
    getProviders: ({ commit, getters }) => api.getProviders(getters.getFilterParams.companyId)
      .then((providers) => commit(mutationTypes.SET_PROVIDERS, providers))
      .catch((err) => handleError({ commit }, err)),
  },
};
