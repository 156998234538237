export default {
  filterConfig: {},
  filterParams: {},
  queries: {},
  languages: [],
  translations: {
    header_route_carousels: 'Carousels',
    header_route_games: 'All Games',
    header_route_popular: 'Popular',
    header_route_groups: 'Groups',
    filter_button_reset_filter: 'Reset Filter',
    filter_search_field: 'Search by text',
    filter_company_dropdown: 'Company',
    filter_category_dropdown: 'Category',
    filter_type_dropdown: 'Type',
    filter_aggregator_dropdown: 'Aggregator',
    filter_provider_dropdown: 'Provider',
    filter_status_dropdown: 'Status',
    filter_activity_dropdown: 'Activity',
    filter_enabled_dropdown: 'Enabled',
    filter_new_only_selector: 'New only',
    filter_enabled_selector: 'Enabled',
    filter_mobile_selector: 'Mobile',
    filter_desktop_selector: 'Desktop',
    filter_live_selector: 'Live',
    filter_standalone_selector: 'Standalone',
    all_games_promoted_games: 'Promoted games',
    all_games_games: 'Games',
    all_games_no_games: 'No game found. Please select different filters!',
    promoted_games_no_games: 'No promoted games yet!',
    all_games_select_company: 'Before start, please select Company!',
    all_games_select_promoted_game: 'Select game from the list',
    all_games_new_game_label: 'New',
    all_games_promoted_game_label: 'Promoted',
    all_games_promote_game_label: 'Promote',
    edit_modal_edit_title: 'Edit',
    edit_modal_game_origin_name: 'Origin name',
    edit_modal_game_display_name: 'Display name',
    edit_modal_game_description: 'Description',
    edit_modal_category_title: 'Category',
    edit_modal_image_url: 'Image URL',
    edit_modal_desktop_image_url: 'Override Desktop Image URL',
    edit_modal_mobile_image_url: 'Override Mobile Image URL',
    edit_modal_sticker_image_url: 'Sticker Image URL',
    edit_modal_new_label: 'New',
    edit_modal_promoted_label: 'Promoted',
    edit_modal_popular_label: 'Popular',
    edit_modal_active_label: 'Active',
    edit_modal_id_label: 'Id',
    edit_modal_priority_label: 'Priority',
    edit_modal_aggregator_label: 'Aggregator',
    edit_modal_provider_label: 'Provider',
    edit_modal_cancel_edit: 'Cancel',
    edit_modal_update_game: 'Update game',
    edit_modal_standalone_label: 'Standalone',
    popular_page_title: 'Select popular games',
    promoted_order_updated: 'Promoted games order updated!',
    popular_order_updated: 'Popular games order updated!',
    order_updated: 'Games order updated',
    game_updated: 'Game updated!',
    popular_game_updated: 'Popular game updated',
    promoted_game_updated: 'Promoted game updated',
    groups_id: '#',
    groups_title: 'Title',
    groups_name: 'Name',
    groups_translation: 'Title translation key',
    group_modal_value_none: 'None',
    edit_modal_updatedAt_label: 'Updated at:',
    edit_modal_createdAt_label: 'Created at:',
    groups_active: 'Active',
    groups_edit: 'Edit',
    group_games_select_all: 'Select all',
    add_group_modal_title: 'Add group',
    add_group_modal_group_title: 'Group title',
    add_group_modal_group_name: 'Group name',
    add_group_modal_group_translation: 'Title translation key',
    add_group_modal_cancel_adding: 'Cancel',
    add_group_modal_add_group: 'Add group',
    edit_modal_group_title: 'Groups',
    filter_group_dropdown: 'Group',
    groups_no_groups: 'Selected company has no groups yet!',
    groups_order_updated: 'Groups order updated!',
    group_updated: 'Group updated',
    group_added: 'Group added',
    group_active: 'Active',
    subgroup_icon: 'Subgroup Icon',
    subgroup_name_label: 'Subgroup name',
    subgroup_title_label: 'Subgroup title',
    subgroup_games_label: 'Subgroup games',
    header_route_tpagames: 'TPA Casino games',
    carousels_page_title: 'Carousels',
    add_carousel_label: 'Add carousel',
    carousel_type_label: 'Carousel type',
    carousel_title_label: 'Carousel title',
    carousel_number_of_games_label: 'Number of games',
    delete_label: 'Delete',
    delete_prompt: 'Are you sure you want to delete selected object?',
    confirm_label: 'Confirm',
    confirm_action: 'Confirm action',
    recently_played: 'Recently Played',
    recommended_for_you: 'Recommended For You',
    top_n: 'Top X in country',
    tournament: 'Tournament Games',
    trending: 'Trending This Week',
    new_releases: 'New Releases',
    because_you_played: 'Because You Played',
  },
  isLoaderVisible: false,
  statusMessage: {},
  user: {},
  userModules: {},
};
