import _orderBy from 'lodash/orderBy';
import _cloneDeep from 'lodash/cloneDeep';
import api from '../../api';
import mutationTypes from '../mutationTypes';
import { handleError, handleSuccess } from '../actions';

export default {
  state: {
    allCompanyGames: [],
    gameLanguageData: {
      en: {
        title: '',
        description: '',
      },
    },
    initialGameLanguageData: {},
    games: [],
    casinoGame: {},
    filterConfig: {
      search: true,
      group: true,
      category: true,
      company: true,
      type: true,
      aggregator: true,
      provider: true,
      enabled: true,
      active: true,
      dropdown: true,
      new: true,
      mobile: true,
      live: true,
      standalone: true,
      tournament: true,
    },
  },

  getters: {
    gamesFilterConfig: (state) => state.filterConfig,
    gameLanguageData: (state) => state.gameLanguageData,
    initialGameLanguageData: (state) => state.initialGameLanguageData,
    sortedAllCompanyGames: (state) => _orderBy(state.allCompanyGames, ['title'], ['asc']),

    games: (state, getters) => {
      const isMobile = getters.getFilterParams.mobile;
      const priority = isMobile ? 'priorityMobile' : 'priority';

      return _orderBy(
        state.games.filter((game) => !game.promoted),
        [priority],
        ['desc'],
      );
    },
    casinoGame: (state) => state.casinoGame,
    promotedGames: (state, getters) => {
      const isMobile = getters.getFilterParams.mobile;
      const priority = isMobile ? 'promotedPriorityMobile' : 'promotedPriority';

      return _orderBy(
        state.games.filter((game) => game.promoted),
        [priority],
        ['desc'],
      );
    },
    popularGames: (state) => _orderBy(
      state.games.filter((game) => game.popular),
      ['popularPriority', 'desc'],
    ),
    nonPopularGames: (state) => _orderBy(
      state.games.filter((game) => !game.popular),
      ['priority', 'desc'],
    ),
  },

  actions: {
    getAllCompanyGames: ({ commit }, params) => api
      .getAllCompanyGames(params)
      .then((games) => commit(mutationTypes.SET_ALL_COMPANY_GAMES, games))
      .catch((err) => handleError({ commit }, err)),

    getCasinoGames: ({ commit }, params) => api
      .getGames(params)
      .then((games) => {
        commit(mutationTypes.SET_GAMES, games);
      })
      .catch((err) => handleError({ commit }, err)),

    getCasinoGame: ({ commit }, params) => api
      .getGame(params)
      .then((game) => {
        commit(mutationTypes.SET_GAME, game);
      })
      .catch((err) => handleError({ commit }, err)),

    clearCasinoGame: ({ commit }) => {
      commit(mutationTypes.SET_GAME, {});
    },

    getGameLanguageData: async ({ commit, dispatch }, params) => {
      try {
        const mergeData = (languages, gameLocales) => {
          const mergedGameLocales = {};

          languages.forEach((language) => {
            mergedGameLocales[language.name] = {
              language: language.name,
              title: '',
              description: '',
            };
          });

          gameLocales.forEach((gameLocale) => {
            mergedGameLocales[gameLocale.language] = gameLocale;
          });
          return mergedGameLocales;
        };

        const gameLocales = await api.getGameLocales(params);
        const languages = await dispatch('getLanguages');

        const mergedLanguageData = mergeData(languages, gameLocales);

        // clone to avoid object reference
        const initialMergedLanguageData = _cloneDeep(mergedLanguageData);

        commit(mutationTypes.SET_INITIAL_GAME_LANGUAGE_DATA, initialMergedLanguageData);
        commit(mutationTypes.SET_GAME_LANGUAGE_DATA, mergedLanguageData);
      } catch (err) {
        handleError({ commit }, err);
      }
    },
    updateGameLocales: ({ commit, rootState }, params) => api
      .updateGameLocales(params)
      .then(() => handleSuccess({ commit }, rootState.translations.languages_updated))
      .catch((err) => handleError({ commit }, err)),

    updateGame: ({ commit, rootState, dispatch }, params) => api
      .updateGame(params)
      .then(() => handleSuccess({ commit }, rootState.translations.game_updated))
      .catch((err) => handleError({ commit }, err))
      .finally(() => dispatch('getCasinoGames', rootState.filterParams)),

    updateGamePopularStatus: ({
      commit, getters, dispatch, rootState,
    }, { gameId, isPopular }) => {
      const { companyId } = getters.getFilterParams;

      return api
        .updateGamePopularStatus({ companyId, gameId, isPopular })
        .then(() => {
          handleSuccess({ commit }, rootState.translations.popular_game_updated);
        })
        .catch((err) => handleError({ commit }, err))
        .finally(() => dispatch('getCasinoGames', getters.getFilterParams));
    },

    async updateGamePromotedStatus(
      {
        commit, getters, dispatch, rootState,
      },
      { gameId, isPromoted },
    ) {
      try {
        await api.updateGamePromotedStatus({
          companyId: getters.getFilterParams.companyId,
          gameId,
          isPromoted,
        });

        await dispatch('getCasinoGames', getters.getFilterParams);

        handleSuccess({ commit }, rootState.translations.promoted_game_updated);
      } catch (error) {
        handleError({ commit }, error);
      }
    },

    updateGamesOrder: ({ commit, rootState, getters }, reorderedGamesIds) => {
      const { companyId, mobile: isMobile } = getters.getFilterParams;

      api
        .updateOrder({ companyId, isMobile, reorderedGamesIds })
        .then(() => {
          handleSuccess({ commit }, rootState.translations.order_updated);
        })
        .catch((err) => handleError({ commit }, err));
    },

    updatePromotedGamesOrder: ({ commit, rootState, getters }, reorderedPromotedGamesIds) => {
      const { companyId, mobile: isMobile } = getters.getFilterParams;

      api
        .updatePromotedOrder({ companyId, isMobile, reorderedPromotedGamesIds })
        .then(() => handleSuccess({ commit }, rootState.translations.order_updated))
        .catch((err) => handleError({ commit }, err));
    },
    updatePopularGamesOrder: ({ commit, getters, rootState }, reorderedPopularGamesIds) => {
      const { companyId } = getters.getFilterParams;

      api
        .updatePopularOrder({ companyId, reorderedPopularGamesIds })
        .then(() => handleSuccess({ commit }, rootState.translations.popular_order_updated))
        .catch((err) => handleError({ commit }, err));
    },
  },

  mutations: {
    [mutationTypes.SET_ALL_COMPANY_GAMES](state, games) {
      state.allCompanyGames = games;
    },
    [mutationTypes.SET_GAMES](state, games) {
      state.games = games;
    },
    [mutationTypes.SET_GAME](state, game) {
      state.casinoGame = game;
    },
    [mutationTypes.SET_GAME_LANGUAGE_DATA](state, languageData) {
      state.gameLanguageData = languageData;
    },
    [mutationTypes.SET_INITIAL_GAME_LANGUAGE_DATA](state, languageData) {
      state.initialGameLanguageData = languageData;
    },
  },
};
