<template>
  <div class="g-input g-input-io modal-input"
       :class="{ 'invalid': !!error }">
    <input class="g-input-input"
           :name="name"
           :value="value"
           :type="type"
           :disabled="disabled"
           :readonly="readOnly"
           @blur="$emit('blur')"
           @focus="$emit('focus')"
           @input="$emit('input', $event.target.value)">
    <div class="g-input-label">
      {{ label || translationKey | translate }}
    </div>
    <div v-show="!!error"
         class="g-input-validation">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    translationKey: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
};
</script>
