import localStorage from '@/utility/localStorage';

const interceptor = (config) => {
  const transformedConfig = config;
  let user = localStorage.getItem('user');

  if (!user) return transformedConfig;

  user = JSON.parse(user);

  transformedConfig.headers.Authorization = user.auth.token;
  transformedConfig.headers['X-Nsft-SCD-Locale'] = user.language;
  transformedConfig.headers.locale = user.language;

  return transformedConfig;
};

const tpaInterceptor = (config) => {
  const transformedConfig = config;
  let user = localStorage.getItem('user');

  if (!user) return transformedConfig;

  user = JSON.parse(user);

  transformedConfig.headers.Authorization = user.auth.token;

  return transformedConfig;
};

export { interceptor };
export { tpaInterceptor };
