import games from './games';
import groups from './groups';
import filters from './filters';
import tpagames from './tpagames';
import carousels from './carousels';

export default {
  games,
  groups,
  filters,
  tpagames,
  carousels,
};
