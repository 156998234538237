import _some from 'lodash/some';
import _isUndefined from 'lodash/isUndefined';

export default {
  translation: (state) => (key) => state.translations[key] || key,
  languages: (state) => state.languages,
  user: (state) => state.user,
  userHasModule: (state) => (key) => (state.userModules[key]
    ? state.userModules[key].view && state.userModules[key].edit
    : false),
  aggregators: (state, getters) => (getters.isTPA ? state.tpagames.tpaAggregators : state.filters.aggregators),
  categories: (state, getters) => (getters.isTPA ? state.tpagames.tpaCategories : state.filters.categories),
  providers: (state, getters) => (getters.isTPA ? state.tpagames.tpaProviders : state.filters.providers),
  isTPA: (state) => state.route.name === 'tpagames',
  getFilterParams: (state) => state.filterParams,
  isReorderingAvailable: (state) => {
    const forbiddenFilters = ['aggregator', 'category', 'provider', 'new', 'type']; // must not have a value
    const strictlyForbiddenFilters = ['enabled']; // must not exist on filterParams at all
    const filtersWithValue = {
      active: true,
    };

    const hasForbiddenFilters = _some(forbiddenFilters, (item) => state.filterParams[item])
      || _some(strictlyForbiddenFilters, (item) => !_isUndefined(state.filterParams[item]));

    const hasForbiddenValues = _some(filtersWithValue, (value, key) => {
      if (_isUndefined(state.filterParams[key])) return false;

      return state.filterParams[key] !== value;
    });

    return !hasForbiddenFilters && !hasForbiddenValues && state.filterParams.standalone;
  },
};
