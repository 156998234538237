const routes = [
  {
    path: 'games/:companyId?',
    name: 'games',
    props: true,
    component: () => import(/* webpackChunkName: "games" */ '../pages/AllGames.vue'),
    meta: { userModule: 'companyAdmin' },
  },
  {
    path: 'popular/:companyId?',
    name: 'popular',
    props: true,
    component: () => import(/* webpackChunkName: "popular" */ '../pages/PopularGames.vue'),
    meta: { userModule: 'companyAdmin' },
  },
  {
    path: 'groups/:companyId?',
    name: 'groups',
    props: true,
    component: () => import(/* webpackChunkName: "popular" */ '../pages/Groups.vue'),
    meta: { userModule: 'companyAdmin' },
  },
  {
    path: 'carousels/:companyId?',
    name: 'carousels',
    props: true,
    component: () => import(/* webpackChunkName: "carousels" */ '../pages/Carousels.vue'),
    meta: { userModule: 'companyAdmin' },
  },
  {
    path: 'tpagames',
    name: 'tpagames',
    props: true,
    component: () => import(/* webpackChunkName: "tpagames" */ '../pages/TPAGames.vue'),
    meta: { userModule: 'tpaGames' },
  },
  {
    path: 'tpaaggregators',
    name: 'tpaaggregators',
    props: true,
    component: () => import(/* webpackChunkName: "tpagames" */ '../pages/TPAGames.vue'),
    meta: { userModule: 'tpaAggregators' },
  },
  {
    path: 'tpaproviders',
    name: 'tpaproviders',
    props: true,
    component: () => import(/* webpackChunkName: "tpagames" */ '../pages/TPAGames.vue'),
    meta: { userModule: 'tpaProviders' },
  },
];

export default routes;
