<template>
  <div class="subgroup">
    <div class="input-header">
      <label>
        {{ `Subgroup ${subgroup.title} ${'#' + (subgroup.id || '')}` }}
      </label>
      <button @click="$emit('remove', subgroup)">
        Remove
      </button>
    </div>
    <div class="flex-row">
      <modal-dropdown-icon v-model="subgroup.icon"
                           :label="$options.filters.translate('subgroup_icon')" />
      <modal-input key="name"
                   v-model="subgroup.name"
                   v-validate="'required|smallcaps_underscores|max:16'"
                   :label="$options.filters.translate('subgroup_name_label')"
                   name="name"
                   :error="errors.first('name')" />
    </div>
    <div class="flex-row">
      <modal-input v-model="subgroup.title"
                   v-validate="'required|max:16'"
                   class="modal-input"
                   :label="$options.filters.translate('subgroup_title_label')"
                   name="title"
                   :error="errors.first('title')" />
      <modal-input key="priority"
                   v-model="subgroup.priority"
                   v-validate="'required|integer|min_value:0|max_value:999'"
                   class="priority"
                   :label="$options.filters.translate('edit_modal_priority_label')"
                   name="priority"
                   :error="errors.first('priority')" />
    </div>
    <modal-dropdown-multiple key="games"
                             class="modal-input"
                             :dropdown-list="dropdownList"
                             :selected-items.sync="subgroup.games"
                             :label="$options.filters.translate('subgroup_games_label')"
                             name="games"
                             :error="errors.first('games')" />
    <app-toggle v-model="subgroup.active"
                name="active"
                :label="$options.filters.translate('group_active')" />
  </div>
</template>

<script>
import _intersectionBy from 'lodash/intersectionBy';
import _cloneDeep from 'lodash/cloneDeep';
import AppToggle from './shared/AppToggle';
import ModalInput from './shared/ModalInput';
import ModalDropdownIcon from './shared/ModalDropdownIcon';
import ModalDropdownMultiple from './shared/ModalDropdownMultiple';

export default {
  name: 'Subgroup',
  components: {
    AppToggle,
    ModalInput,
    ModalDropdownIcon,
    ModalDropdownMultiple,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    dropdownList: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    subgroup: {},
  }),
  watch: {
    subgroup: {
      handler(newVal) {
        this.subgroup = Object.assign(this.subgroup, newVal);
        this.$emit('input', this.subgroup);
      },
      deep: true,
    },
    dropdownList: {
      handler(newVal) {
        this.subgroup.games = _intersectionBy(this.subgroup.games, newVal, 'id');
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.$validator.pause();
  },
  created() {
    this.subgroup = _cloneDeep(this.value);
  },
  methods: {
    // eslint-disable-next-line
    validateRules() {
      this.subgroup.validationFinished = false;
      this.$validator.validate().then((valid) => {
        this.subgroup.valid = valid;
        this.subgroup.validationFinished = true;
        this.$emit('validationFinished');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subgroup {
  margin: 1.5rem 0;
}

.modal-input {
  margin-bottom: 1rem;
}

.input-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $font-primary-1;
  margin-bottom: 0.5rem;

  label {
    font-size: $fs-500;
  }

  button {
    text-transform: uppercase;
    font-size: $fs-200;
    font-weight: bold;
  }
}

.priority {
  margin-left: 1rem;
  width: 35%;
}

</style>
