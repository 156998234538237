<template>
  <modal name="group-modal"
         :adaptive="true"
         height="auto"
         class="modal"
         scrollable
         @before-open="beforeOpen"
         @before-close="beforeClose">
    <!-- Modal header -->
    <div class="modal-header">
      <label class="modal-header-title">
        {{ title | translate }}
      </label>
      <div class="modal-header-close"
           @click="$modal.hide('group-modal')">
        <span class="g-icon g-icon-close-a" />
      </div>
    </div>
    <!-- Modal content -->
    <div class="modal-content">
      <div class="flex-row">
        <modal-dropdown-icon v-model="group.icon"
                             :label="$options.filters.translate('group_icon')" />
        <modal-input key="name"
                     v-model="group.name"
                     v-validate="'required|smallcaps_underscores|max:16'"
                     :label="$options.filters.translate('add_group_modal_group_name')"
                     name="name"
                     :error="errors.first('name')" />
      </div>
      <modal-input key="title"
                   v-model="group.title"
                   v-validate="'required|max:16'"
                   class="modal-input"
                   :label="$options.filters.translate('add_group_modal_group_title')"
                   name="title"
                   :error="errors.first('title')" />
      <modal-dropdown-multiple key="games"
                               class="modal-input"
                               :dropdown-list="sortedAllCompanyGames"
                               :selected-items.sync="group.games"
                               :label="$options.filters.translate('group_games_label')"
                               name="games" />
      <app-toggle v-model="group.active"
                  name="group_active"
                  :label="$options.filters.translate('group_active')" />
      <subgroup v-for="(subgroup, i) in group.subgroups"
                :key="subgroup.id"
                ref="subgroupRef"
                v-model="group.subgroups[i]"
                :dropdown-list="group.games"
                :value="group.subgroups[i]"
                @input="subgroupGamesUpdated(subgroup)"
                @remove="removeSubgroup"
                @validationFinished="validateSubgroups" />
      <div class="my-1 add-button">
        <button class="g-button g-button-earth mr-1"
                :disabled="disableAddSubgroup"
                @click="addSubgroup">
          <i class="g-icon g-icon-plus" />
        </button>
        <span>{{ 'add_new_subgroup_key' | translate }}</span>
      </div>
    </div>
    <!-- Modal buttons -->
    <div class="modal-footer">
      <button class="g-button g-button-earth"
              @click="$modal.hide('group-modal')">
        <span class="g-button-title">
          {{ 'add_group_modal_cancel_adding' | translate }}
        </span>
      </button>
      <button class="g-button g-button-earth"
              @click="validateRules">
        <span class="g-button-title">{{ title | translate }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _cloneDeep from 'lodash/cloneDeep';
import _differenceBy from 'lodash/differenceBy';
import toBool from '../utility/toBool';
import ModalInput from './shared/ModalInput';
import ModalDropdownIcon from './shared/ModalDropdownIcon';
import ModalDropdownMultiple from './shared/ModalDropdownMultiple';
import AppToggle from './shared/AppToggle';
import Subgroup from './Subgroup';

export default {
  name: 'GroupModal',
  components: {
    ModalInput,
    ModalDropdownIcon,
    ModalDropdownMultiple,
    AppToggle,
    Subgroup,
  },
  data: () => ({
    title: '',
    submitAction: '',
    group: {},
    pristineGroup: {},
    emptyGroup: {
      title: '',
      games: [],
      name: '',
      titleTranslationKey: '',
      icon: '',
      active: true,
      valid: false,
      subgroups: [],
    },
    emptySubgroup: {
      name: '',
      title: '',
      titleTranslationKey: '',
      active: true,
      games: [],
      priority: 0,
      valid: false,
      validationFinished: false,
    },
  }),
  computed: {
    disableAddSubgroup() {
      const { subgroups } = this.group;
      return subgroups && _isEqual(this.emptySubgroup, _omit(subgroups[subgroups.length - 1], ['valid']));
    },
    sortedAllCompanyGames() {
      return this.$store.getters.sortedAllCompanyGames;
    },
    currentCasinoGamesGroup() {
      return this.$store.state.groups.currentCasinoGamesGroup;
    },
  },
  methods: {
    subgroupGamesUpdated(subgroup) {
      _differenceBy(subgroup.games, this.group.games, 'id').forEach((game) => this.group.games.push(game));
    },
    validateRules() {
      this.$validator.validate().then((valid) => {
        this.group.valid = valid;

        if (this.group.subgroups.length > 0) {
          this.$refs.subgroupRef.forEach((ref) => {
            ref.validateRules();
          });
        } else {
          if (!valid) return;
          this.updateGroup();
        }
      });
    },
    updateGroup() {
      const { companyId } = this.$route.params;
      this.$store.dispatch(this.submitAction, {
        companyId,
        group: {
          ...this.group,
          games: this.group.games.map((game) => game.id),
          subgroups: this.group.subgroups.map((subgroup) => ({
            ...subgroup,
            games: subgroup.games.map((game) => game.id),
          })),
        },
      }).then((err) => {
        if (!err) {
          this.$modal.hide('group-modal');
        }
      });
    },
    addSubgroup() {
      this.group.subgroups.push({ ...this.emptySubgroup });

      this.group.subgroups.forEach((subgroup) => {
        // eslint-disable-next-line no-param-reassign
        subgroup.priority = parseInt(subgroup.priority, 10) + 1;
      });
    },
    removeSubgroup(subgroup) {
      this.group.subgroups = this.group.subgroups.filter((e) => !_isEqual(subgroup, e));
    },
    validateSubgroups() {
      const { subgroups } = this.group;
      if (!this.group.valid
        || !!subgroups.some((sg1) => !sg1.validationFinished || !sg1.valid
          || subgroups.some((sg2) => sg1 !== sg2 && sg1.name === sg2.name))) {
        return;
      }

      this.updateGroup();
    },
    async beforeOpen(event) {
      const { groupId, submitAction } = event.params;
      this.submitAction = submitAction;
      this.title = groupId ? 'Edit group' : 'Create group';

      this.group = _cloneDeep(this.emptyGroup);
      this.$validator.resume();

      if (groupId) {
        await this.$store.dispatch('getCasinoGamesGroup', {
          companyId: this.$route.params.companyId,
          groupId,
        });
        this.group = Object.assign(this.group, {
          ...this.currentCasinoGamesGroup,
          active: toBool(this.currentCasinoGamesGroup.active),
          subgroups: this.currentCasinoGamesGroup.subgroups.map((subgroup) => ({
            ...subgroup,
            active: toBool(subgroup.active),
          })),
        });
      }

      this.pristineGroup = _cloneDeep(this.group);
    },
    beforeClose() {
      this.group = _cloneDeep(this.emptyGroup);
      this.$validator.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_modal";

.add-button {
  span:hover {
    cursor: default;
  }
}

.flex-row {
  display: flex;
  align-items: flex-start;
}

::v-deep .g-input.g-input-io .g-input-validation {
  height: fit-content;
  padding: 0.5em;
  font-size: 0.7em;
  right: 0;
  left: auto;
}

</style>
