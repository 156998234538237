import dayjs from '@/utility/dayjs';
import api from '../../api';
import mutationTypes from '../mutationTypes';
import { handleError, handleSuccess } from '../actions';

export default {
  state: {
    casinoGamesGroups: [],
    currentCasinoGamesGroup: {},
    filterConfig: {
      search: true,
      company: true,
    },
  },

  getters: {
    groupsFilterConfig: (state) => state.filterConfig,
    casinoGamesGroups: (state) => state.casinoGamesGroups
      .map((group) => ({
        ...group,
        updatedAt: group.updatedAt ? dayjs(group.updatedAt).format('lll') : 'N/A',
        createdAt: group.createdAt ? dayjs(group.createdAt).format('lll') : 'N/A',
      }))
      .sort((a, b) => b.priority - a.priority),
  },

  actions: {
    getCasinoGamesGroups: ({ commit }, params) => api.getCasinoGamesGroups(params)
      .then((groups) => commit(mutationTypes.SET_CASINO_GAMES_GROUPS, groups))
      .catch((err) => handleError({ commit }, err)),
    getCasinoGamesGroup: ({ commit }, params) => api.getCasinoGamesGroup(params)
      .then((group) => commit(mutationTypes.SET_CURRENT_CASINO_GAMES_GROUP, group))
      .catch((err) => handleError({ commit }, err)),
    createCasinoGamesGroup: ({ commit, rootState, dispatch }, params) => api
      .createCasinoGamesGroup(params)
      .then(() => handleSuccess({ commit }, 'Successfully created new group!'))
      .catch((err) => {
        handleError({ commit }, err);
        return err;
      })
      .finally(() => dispatch('getCasinoGamesGroups', rootState.filterParams)),
    updateCasinoGamesGroup: ({ commit, rootState, dispatch }, params) => api
      .updateCasinoGamesGroup(params)
      .then(() => handleSuccess({ commit }, 'Successfully updated group data!'))
      .catch((err) => {
        handleError({ commit }, err);
        return err;
      })
      .finally(() => dispatch('getCasinoGamesGroups', rootState.filterParams)),
    updateGroupsOrder: ({ commit, rootState, dispatch }, params) => api
      .updateGroupsOrder(params)
      .then(() => handleSuccess({ commit }, 'Successfully updated group order!'))
      .catch((err) => handleError({ commit }, err))
      .finally(() => dispatch('getCasinoGamesGroups', rootState.filterParams)),
  },

  mutations: {
    [mutationTypes.SET_CASINO_GAMES_GROUPS](state, groups) {
      state.casinoGamesGroups = groups;
    },
    [mutationTypes.SET_CURRENT_CASINO_GAMES_GROUP](state, group) {
      state.currentCasinoGamesGroup = group;
    },
  },
};
