import Gateway from '@nsoft/seven-gravity-gateway/slave';

const filterAllowedOrigins = (allowedOrigins) => {
  if (window.location.ancestorOrigins) {
    const allowedOriginIndex = allowedOrigins.indexOf(window.location.ancestorOrigins[0]);
    if (allowedOriginIndex > -1) {
      return [allowedOrigins[allowedOriginIndex]];
    }
  }
  return allowedOrigins;
};

const allowedOrigins = process.env.VUE_APP_ALLOWED_INTEGRATOR_ORIGINS.split(',');
const slaveId = process.env.VUE_APP_INTEGRATION_ID;

const integration = {
  init() {
    return new Promise((resolve) => {
      this.gateway = Gateway({
        load: (message) => {
          resolve(message);
        },
        slaveId,
        allowedOrigins: filterAllowedOrigins(allowedOrigins),
      });
    });
  },
  sendMessage(message) {
    if (!this.gateway) return;
    this.gateway.sendMessage(message);
  },
  subscribe(event, callback) {
    if (!this.gateway) return;
    this.gateway.subscribe(event, callback);
  },
};

export default integration;
