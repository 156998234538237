import Vue from 'vue';
import VModal from 'vue-js-modal';
import VeeValidate, { Validator } from 'vee-validate';

Vue.use(VModal);
Vue.use(VeeValidate);

Validator
  .extend('smallcaps_underscores', {
    validate: (value) => ({
      valid: value.match(/^[a-z]+(_[a-z]+)*$/),
      data: {
        message: value[value.length - 1] === '_'
          ? 'This field must not end in underscore!'
          : 'This field can contain only small caps and underscores!',
      },
    }),
    getMessage: (field, params, data) => data.message,
  });

Validator
  .extend('image_url', {
    validate: (value) => ({
      // eslint-disable-next-line
      valid: value.length <= 256 && value.match(/^https?:\/\/[a-zA-Z0-9-_~:?#[\]@!$&'()*+,;=\.\/%]{1,237}\.(png|jpg|jpeg|gif|svg)$/),
      data: {
        message: 'This field must be a valid image URL!',
      },
    }),
    getMessage: (field, params, data) => data.message,
  });

export default {};
