import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Integration from '@/services/integration';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "app-template" */ '../layouts/AppTemplate.vue'),
      children: routes,
      redirect: 'games',
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import(/* webpackChunkName: "forbidden" */ '../pages/Forbidden.vue'),
      meta: { userModule: null },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.meta.userModule || from.fullPath === to.fullPath) {
    next();
    return;
  }

  if (!store.getters.userHasModule(to.meta.userModule)) {
    next({
      name: 'forbidden',
    });
  } else {
    store.dispatch('clearStatusMessage');

    if (Integration) {
      Integration.sendMessage({
        action: 'Router.ChangeRoute',
        data: {
          route: `/casino-admin${to.fullPath}`,
        },
      });
    }

    next();
  }
});
export default router;
