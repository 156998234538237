<template>
  <div v-clickout="deactivateDropdown"
       class="g-input g-input-io group-modal-input">
    <div class="g-input-header">
      <div class="g-input-label">
        {{ label }}
      </div>
    </div>
    <div class="g-input-input"
         type="text"
         @click="toggleDropdown">
      <i v-if="selectedIcon"
         :class="selectedIcon | iconize"
         class="g-icon selected-icon" />
      <span v-else>
        {{ 'group_modal_value_none' | translate }}
      </span>
      <i :class="dropdownActive ? 'g-icon-arrow-up-d' : 'g-icon-arrow-down-d'"
         class="g-icon g-input-dropdown__icon" />
    </div>
    <div v-if="dropdownActive"
         class="g-input-dropdown">
      <div class="g-input-dropdown__search">
        <input v-model="search"
               name="search"
               type="text"
               :placeholder="$options.filters.translate('group_games_search')">
      </div>
      <div v-if="!search.length"
           class="g-input-dropdown__subtext"
           @click="selectIcon">
        {{ 'group_modal_value_none' | translate }}
      </div>
      <div class="g-input-dropdown__droplist">
        <div v-for="item in filteredIcons"
             :key="item.cssClass"
             class="droplist-icon"
             :class="{'active': isActive(item.cssClass)}"
             :tip="item.cssClass"
             @click.stop="selectIcon($event, item.cssClass)">
          <i :class="item.cssClass | iconize"
             class="g-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { icons } from '@nsoft/nfont/src/data/icons';
import clickout from '@/directives/clickout';

export default {
  name: 'ModalDropdownIcon',
  filters: {
    iconize(value) {
      return value ? value.replace('n-i-', 'g-icon-') : '';
    },
  },
  directives: {
    clickout,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    selectedIcon: '',
    nfont: {},
    search: '',
    dropdownActive: false,
  }),
  computed: {
    filteredIcons() {
      const regex = new RegExp(this.search, 'i');
      return this.nfont.filter((item) => item.cssClass.match(regex));
    },
    isActive() {
      return (cssClass) => this.selectedIcon === cssClass;
    },
  },
  watch: {
    value(newVal) {
      this.selectedIcon = newVal;
    },
  },
  created() {
    this.selectedIcon = this.value;
    this.nfont = icons.map((icon) => ({
      ...icon,
      cssClass: icon.cssClass.split(' ')[0],
    }));
  },
  methods: {
    toggleDropdown() {
      this.dropdownActive = !this.dropdownActive;
    },
    deactivateDropdown() {
      this.dropdownActive = false;
    },
    selectIcon(e, icon) {
      this.deactivateDropdown();
      this.$emit('input', icon);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_input-dropdown";

.group-modal-input {
  margin-bottom: 1em;
  max-width: 30%;
  margin-right: 1rem;
}

.g-input-label {
  z-index: 10;
}

.g-input-input {
  position: relative;
  display: flex;
  align-items: center;
  height: auto !important;
  max-height: 250px;

  .selected-icon {
    font-size: 1.5rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.g-input-dropdown {
  width: 320px;
  background:#fff;
  box-shadow: $shadow-dropdown;
  overflow-y: scroll;

  @include breakpoint-regular {
    width: 520px;
  }

  &__search {
    padding: 1px;

    input {
      background-color: $bg-200;
      width: 100%;
      border: none;
      padding: 0.8rem;
      color: $font-primary-1;

      &::placeholder {
        color: $font-primary-3;
      }
    }
  }

  &__icon {
    position: absolute;
    right: 1.3rem;
    top: 50%;
    transform:translateY(-50%);
    z-index: 1000;
  }

  &__subtext {
    padding: 0.8rem;
    color: $font-primary-2;
    cursor: pointer;

    &:hover {
      background-color: $bg-100;
    }
  }

  &__droplist {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3rem, 1fr));
    grid-gap: 2px;
    max-height: 15rem;
    padding-right: 0.5rem;

    .droplist-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 3rem;
      min-height: 3rem;
      color: $accent;
      font-size: 1.5rem;
      cursor: pointer;

      &.active {
        color: white;
        font-weight: 600;
        background-color: $brand;

        &:hover {
          background-color: $brand-lighter;
        }
      }

      &:hover {
        background-color: $bg-100;
      }

      &::placeholder {
        color: $font-primary-3;
      }
    }
  }
}
</style>
