<template>
  <div class="toggle-container">
    <span class="toggler-label">{{ translationKey | translate }}</span>
    <div class="g-toggle g-toggle-europa">
      <input class="g-toggle-input"
             type="checkbox"
             :name="name"
             :checked="checked"
             :true-value="true"
             :false-value="false"
             :disabled="disabled"
             @change="checked = !checked">
      <label class="g-toggle-label"
             :for="translationKey" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppToggle',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    translationKey: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.toggler-label {
  color: $font-primary-1;
  font-weight: bold;
  margin-right: 0.5rem;
}

.g-toggle.g-toggle-europa {
  .g-toggle-label {
    &:before {
      background-color: $font-primary-3;
    }

    &:after {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
