import http from './http';

const getAllCompanyGames = ({ companyId }) => {
  const url = `/casino/admin/companies/${companyId}/products/basic`;

  return http.get(url, {
    timeout: 20000,
  }).then((res) => res.data.products);
};

const getGames = ({ companyId, ...params }) => {
  const url = `/casino/admin/companies/${companyId}/products`;

  return http.get(
    url,
    {
      params,
      timeout: 20000,
    },
  ).then((res) => res.data.products);
};

const getGame = ({ companyId, gameId }) => {
  const url = `/casino/admin/companies/${companyId}/products/${gameId}`;

  return http.get(
    url,
    {
      timeout: 20000,
    },
  ).then((res) => res.data);
};

const getGameLocales = async ({ companyId, productId }) => {
  const { data } = await http.get(
    `/casino/admin/companies/${companyId}/products/${productId}/locale`,
  );

  return data.locales;
};

const updateGameLocales = async ({ companyId, productId, gameLocales }) => {
  const requestBody = { locales: gameLocales };
  const { data } = await http.put(
    `/casino/admin/companies/${companyId}/products/${productId}/locale`,
    requestBody,
  );

  return data;
};

const updateGame = ({ params, game }) => {
  const url = `casino/admin/companies/${params.companyId}/products/${params.productId}`;

  return http.patch(url, game);
};

const updateGamePopularStatus = ({ companyId, gameId, isPopular }) => {
  const url = `casino/admin/companies/${companyId}/products/${gameId}`;

  return http.patch(url, {
    popular: isPopular,
  });
};

const updateGamePromotedStatus = ({ companyId, gameId, isPromoted }) => {
  const url = `casino/admin/companies/${companyId}/products/${gameId}/promoted`;

  return http.put(url, {
    promoted: isPromoted,
  });
};

const updateOrder = ({ companyId, isMobile, reorderedGamesIds: order }) => {
  const url = `casino/admin/companies/${companyId}/products/order${isMobile ? '/mobile' : ''}`;

  return http.patch(url, { order });
};

const updatePromotedOrder = ({ companyId, isMobile, reorderedPromotedGamesIds: promotedOrder }) => {
  const url = `casino/admin/companies/${companyId}/products/promoted/order${isMobile ? '/mobile' : ''}`;

  return http.patch(url, { promotedOrder });
};

const updatePopularOrder = ({ companyId, reorderedPopularGamesIds: popularOrder }) => {
  const url = `casino/admin/companies/${companyId}/products/popular/order`;

  return http.patch(url, { popularOrder });
};

export default {
  getAllCompanyGames,
  getGameLocales,
  updateGameLocales,
  getGames,
  getGame,
  updateGamePromotedStatus,
  updateGamePopularStatus,
  updateGame,
  updateOrder,
  updatePromotedOrder,
  updatePopularOrder,
};
