import isServer from '../utility/isServer';

const validate = (binding) => {
  if (typeof binding.value !== 'function') {
    return false;
  }

  return true;
};

const isPopup = (popupItem, elements) => {
  if (!popupItem || !elements) {
    return false;
  }

  // eslint-disable-next-line
  for (let i = 0, len = elements.length; i < len; i++) {
    try {
      if (popupItem.contains(elements[i])) {
        return true;
      }
      if (elements[i].contains(popupItem)) {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  return false;
};

export default {
  bind: (el, binding, vNode) => {
    if (!validate(binding)) {
      return;
    }

    const elem = el;

    const handler = (e) => {
      if (!vNode.context) {
        return;
      }

      const elements = e.path || (e.composedPath && e.composedPath());
      if (elements && elements.length > 0) {
        elements.unshift(e.target);
      }

      if (el.contains(e.target) || isPopup(vNode.context.popupItem, elements)) {
        return;
      }

      elem.__vueClickout__.callback(e);
    };

    elem.__vueClickout__ = {
      handler,
      callback: binding.value,
    };

    if (!isServer(vNode)) {
      document.addEventListener('click', handler);
    }
  },
  update: (el, binding) => {
    if (!validate(binding)) return;

    const elem = el;
    elem.__vueClickout__.callback = binding.value;
  },
  unbind: (el, binding, vNode) => {
    if (isServer(vNode)) return;

    const elem = el;
    document.removeEventListener('click', elem.__vueClickout__.handler);
    delete elem.__vueClickout__;
  },
};
