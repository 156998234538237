import http from './http';

const getCasinoGamesGroups = ({ companyId, ...params }) => {
  const url = `/casino/admin/companies/${companyId}/groups`;
  return http.get(url, {
    params: {
      ...params,
      showInactive: true,
      pageSize: 1000,
    },
  }).then((res) => res.data.groups);
};

const getCasinoGamesGroup = ({ companyId, groupId }) => {
  const url = `/casino/admin/companies/${companyId}/groups/${groupId}`;
  return http.get(url, {
    params: {
      showInactive: true,
    },
  }).then((res) => res.data);
};

const createCasinoGamesGroup = ({ companyId, group }) => {
  const url = `/casino/admin/companies/${companyId}/groups`;
  return http.post(url, group)
    .then((res) => res.data);
};

const updateCasinoGamesGroup = ({ companyId, group }) => {
  const url = `/casino/admin/companies/${companyId}/groups/${group.id}`;
  return http.put(url, group)
    .then((res) => res.data);
};

const updateGroupsOrder = ({ companyId, order }) => {
  const url = `casino/admin/companies/${companyId}/groups/order`;
  return http.patch(url, { order })
    .then((res) => res.data);
};

export default {
  getCasinoGamesGroups,
  getCasinoGamesGroup,
  createCasinoGamesGroup,
  updateCasinoGamesGroup,
  updateGroupsOrder,
};
