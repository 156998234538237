export default {
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_FILTER_CONFIG: 'SET_FILTER_CONFIG',

  // games module
  SET_GAMES: 'SET_GAMES',
  SET_GAME: 'SET_GAME',
  SET_ALL_COMPANY_GAMES: 'SET_ALL_COMPANY_GAMES',
  SET_GAME_LANGUAGE_DATA: 'SET_GAME_LANGUAGE_DATA',
  SET_INITIAL_GAME_LANGUAGE_DATA: 'SET_INITIAL_GAME_LANGUAGE_DATA',

  SET_CASINO_GAMES_GROUPS: 'SET_CASINO_GAMES_GROUPS',
  SET_CURRENT_CASINO_GAMES_GROUP: 'SET_CURRENT_CASINO_GAMES_GROUP',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_TYPES: 'SET_TYPES',
  SET_AGGREGATORS: 'SET_AGGREGATORS',
  SET_PROVIDERS: 'SET_PROVIDERS',
  SET_COMPANIES: 'SET_COMPANIES',
  UPDATE_CASINO_GAMES_ORDER: 'UPDATE_CASINO_GAMES_ORDER',
  UPDATE_PROMOTED_CASINO_GAMES_ORDER: 'UPDATE_PROMOTED_CASINO_GAMES_ORDER',
  UPDATE_POPULAR_CASINO_GAMES_ORDER: 'UPDATE_POPULAR_CASINO_GAMES_ORDER',
  UPDATE_GROUPS_ORDER: 'UPDATE_GROUPS_ORDER',
  SET_FILTER_PARAMS: 'SET_FILTER_PARAMS',
  SET_STATUS_MESSAGE: 'SET_STATUS_MESSAGE',
  CLEAR_STATUS_MESSAGE: 'CLEAR_STATUS_MESSAGE',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  SET_USER: 'SET_USER',
  SET_USER_MODULES: 'SET_USER_MODULES',
  SET_ALL_TPA_GAMES: 'SET_ALL_TPA_GAMES',
  SET_TPA_AGGREGATORS: 'SET_TPA_AGGREGATORS',
  SET_TPA_CATEGORIES: 'SET_TPA_CATEGORIES',
  SET_TPA_PROVIDERS: 'SET_TPA_PROVIDERS',
  SET_TPA_TYPES: 'SET_TPA_TYPES',
  SET_MESSAGES_FROM_API: 'SET_MESSAGES_FROM_API',
  SET_CASINO_CAROUSELS: 'SET_CASINO_CAROUSELS',
  SET_CASINO_CAROUSEL_TYPES: 'SET_CASINO_CAROUSEL_TYPES',
};
