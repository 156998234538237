<template>
  <v-app id="app">
    <router-view />
    <group-modal />
    <action-message />
    <app-loader />
  </v-app>
</template>

<script>
import GroupModal from '@/components/GroupModal';
import ActionMessage from '@/components/ActionMessage';
import AppLoader from '@/components/shared/AppLoader';
import Integration from '@/services/integration';

export default {
  name: 'App',
  components: {
    GroupModal,
    ActionMessage,
    AppLoader,
  },
  created() {
    Integration.sendMessage({
      action: 'Slave.Loaded',
      data: {
        message: 'Casino Admin Loaded',
      },
    });

    Integration.subscribe('Router.RouteChanged', (route) => {
      if (!route.data) return;
      const { path } = route.data;

      if (
        !path
        || (path === '/games' && this.$route.name === 'games')
        || (path === '/popular' && this.$route.name === 'popular')
        || (path === '/groups' && this.$route.name === 'groups')
        || (path === '/tpagames' && this.$route.name === 'tpagames')
      ) {
        return;
      }

      this.$router.push({ path: route.data.path });
    });
  },
};
</script>

<style lang="scss">
@import "./assets/theme/_clarity";
@import "./assets/external/_icons";

#app {
  background-color: $bg-100;
}
</style>
