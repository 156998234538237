import axios from 'axios';
import { interceptor } from './interceptors';

const baseURL = process.env.VUE_APP_GRAVITY_API_BASEURL;

const http = axios.create({
  baseURL,
  timeout: 5000,
});

http.interceptors.request.use(interceptor, (error) => Promise.reject(error));

const getTranslations = () => http.get('casino-admin/translations').then((res) => res.data);

export default getTranslations;
