<template>
  <div v-clickout="deactivateDropdown"
       class="g-input g-input-io group-modal-input"
       :class="{'disabled': !dropdownList.length}">
    <div class="g-input-header">
      <div class="g-input-label">
        {{ label }}
      </div>
    </div>
    <div ref="games"
         class="g-input-input"
         type="text"
         @click="toggleDropdown">
      <div v-for="item in selectedItems"
           :key="item.id"
           class="g-input-input-chip"
           :class="{'inactive': !item.active }">
        {{ item.title }}
        <i v-if="item.desktop"
           class="g-input-input-chip-icon g-icon-desktop g-icon" />
        <i v-if="item.mobile"
           class="g-input-input-chip-icon g-icon-mobile g-icon" />
        <i class="g-input-input-chip-close g-icon"
           @click.stop="toggleItem($event, item)" />
      </div>
      <i :class="dropdownActive ? 'g-icon-arrow-up-d' : 'g-icon-arrow-down-d'"
         class="g-icon g-input-dropdown__icon" />
    </div>
    <div v-if="dropdownActive"
         class="g-input-dropdown">
      <div class="g-input-dropdown__search">
        <input v-model="search"
               name="search"
               type="text"
               :placeholder="$options.filters.translate('group_games_search')">
        <div class="g-input-dropdown__select-all">
          <div class="capitalize">
            {{ 'group_games_select_all' | translate }}
          </div>
          <i class="droplist-item-check g-icon"
             :class="{'active': isAllSelected}"
             @click="selectAll" />
        </div>
      </div>
      <div class="g-input-dropdown__droplist"
           :class="{ 'active': dropdownActive }">
        <div v-for="item in filteredDropdownList"
             :key="item.id"
             class="droplist-item"
             @click.stop="toggleItem($event, item)">
          <i class="droplist-item-check g-icon"
             :class="{'active': isActive(item.id)}" />
          <p class="droplist-item-text">
            {{ item.title }}
          </p>
          <span v-if="item.desktop"
                class="droplist-item-subtext">{{ 'filter_desktop_selector' | translate }}</span>
          <span v-if="item.desktop && item.mobile"
                class="droplist-item-subtext">/</span>
          <span v-if="item.mobile"
                class="droplist-item-subtext">{{ 'filter_mobile_selector' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clickout from '../../directives/clickout';

export default {
  name: 'ModalDropdownMultiple',
  directives: {
    clickout,
  },
  props: {
    dropdownList: {
      type: Array,
      default: () => ([]),
    },
    selectedItems: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    search: '',
    dropdownActive: false,
    isAllSelected: false,
  }),
  computed: {
    filteredDropdownList() {
      const regex = new RegExp(this.search, 'i');
      return this.dropdownList.filter((item) => item.title.match(regex));
    },
    isActive() {
      return (id) => !!this.selectedItems.find((item) => item.id === id);
    },
  },
  watch: {
    selectedItems() {
      const { games } = this.$refs;
      this.isAllSelected = this.dropdownList.length === this.selectedItems.length;
      this.$nextTick(() => {
        games.scrollTop = games.scrollHeight;
      });
    },
  },
  methods: {
    selectAll() {
      this.isAllSelected = !this.isAllSelected;
      this.$emit('update:selectedItems', this.isAllSelected ? this.dropdownList : []);
    },
    deactivateDropdown() {
      this.dropdownActive = false;
    },
    toggleDropdown() {
      if (!this.dropdownList.length) return;
      this.dropdownActive = !this.dropdownActive;
    },
    toggleItem(e, item) {
      const index = this.selectedItems.findIndex((selected) => selected.id === item.id);
      if (index !== -1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedItems.splice(index, 1);
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.selectedItems.push(item);
      }
      this.$emit('update:selectedItems', this.selectedItems);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_input-dropdown";

.g-input-label {
  z-index: 10;
}

.g-input-input {
  height: auto !important;
  max-height: 250px;
  overflow-y: auto;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.g-input-input-chip {
  display: inline-block;
  color: $font-secondary-1;
  font-size: $fs-200;
  font-weight: 600;
  background: $brand;
  margin-right: 0.1rem;
  margin-bottom: 0.1rem;
  padding: 0.5rem;

  &.inactive {
    background-color: $bg-200;
    color: $font-primary-3;
  }

  &-icon {
    margin-left: 0.125rem;
    opacity: 0.5;
  }

  &-close {
    &:before {
      content: '\e70d';
    }
  }
}

.g-input-dropdown {
  &__icon {
    position: absolute;
    right: 1.3rem;
    top: 0.75rem;
    z-index: 10000;
  }

  &__search {
    position: relative;

    .g-input-dropdown__select-all {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      color: $font-primary-2;
      font-size: $fs-300;
      cursor: pointer;
    }
  }

  &__droplist {
    max-height: 15rem;
    overflow-y: auto;

    .droplist-item {
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 1rem;
      padding: 0.5rem;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }

  .droplist-item-check {
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    border: thin solid $font-primary-1;

    &.active {
      position: relative;
      color: $font-secondary-1;
      background-color: $font-primary-1;

      &:before {
        content: '\E70E';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .droplist-item-text {
    flex: 1;
    margin: 0;
    color: $font-primary-1;
  }

  .droplist-item-subtext {
    color: $font-primary-3;
    font-size: $fs-200;
    text-transform: uppercase;
    margin-left: 0.5rem;
  }
}
</style>
