import games from './games';
import groups from './groups';
import filters from './filters';
import tpagames from './tpagames';
import carousels from './carousels';
import getTranslations from './translations';
import getLanguages from './languages';

export default {
  ...games,
  ...groups,
  ...filters,
  ...tpagames,
  ...carousels,
  getTranslations,
  getLanguages,
};
